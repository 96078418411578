import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { detectUserLanguage, getLanguageFromURL } from '../utils/languageDetector';

import enTranslations from './locales/en.json';
import frTranslations from './locales/fr.json';
import nlTranslations from './locales/nl.json';

// Initialize i18next
i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslations
    },
    fr: {
      translation: frTranslations
    },
    nl: {
      translation: nlTranslations
    }
  },
  lng: getLanguageFromURL() || detectUserLanguage(),
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  }
});

// Handle language changes
export const changeLanguage = async (language) => {
  try {
    const currentPath = window.location.pathname;
    const pathParts = currentPath.split('/').filter(Boolean);
    
    // If first part is a language code, replace it; otherwise, add language
    if (pathParts[0] && ['en', 'fr', 'nl'].includes(pathParts[0])) {
      pathParts[0] = language;
    } else {
      pathParts.unshift(language);
    }
    
    const newPath = `/${pathParts.join('/')}`;
    window.history.pushState({}, '', newPath);
    
    await i18n.changeLanguage(language);
  } catch (error) {
    console.error('Error changing language:', error);
  }
};

export default i18n; 