import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Subscribe = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js-eu1.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "eu1",
          portalId: "143745122",
          formId: "5402ad14-69dc-4798-90ba-f56e7c2b527a",
          target: '#hubspotForm'
        })
      }
    });
  }, []);
  // <script charset="utf-8" type="text/javascript" src="//js-eu1.hsforms.net/forms/embed/v2.js"></script>
  // <script>
  //   hbspt.forms.create({
  //     region: "eu1",
  //   portalId: "143745122",
  //   formId: "5402ad14-69dc-4798-90ba-f56e7c2b527a"
  //   });
  // </script>

  return (
    <>
      <div className="max-w-xl lg:max-w-lg ">
        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
          {t('subscribe.title')}
        </h2>
        <p className="mt-4 text-lg leading-8 text-white pb-6">
          {t('subscribe.description')}
        </p>
        {/* <form onSubmit={handleSubmit} className="">
          <div className="mt-6 flex max-w-md gap-x-4">
            <label htmlFor="email-address" className="sr-only">Email address</label>
            <input id="email-address"
              name="email"
              type="email"
              autoComplete="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" placeholder="Enter your email" />
            <button type="submit"
              className="flex-none rounded-md bg-gray-800 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm  focus:outline-none focus:ring focus:ring-gray-700">Subscribe</button>
          </div>
        </form> */}
        <div id="hubspotForm"></div>
      </div>

    </>
  );
}

export default Subscribe;
