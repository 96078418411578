import { useTranslation } from 'react-i18next';
import StatContainer from "../../components/StatContainer";
import agents from "../../assets/images/agents.jpg";
import webDev from "../../assets/images/web-dev.jpg";
import automation from "../../assets/images/automation.jpg";
import team from "../../assets/images/team.jpg";
import ai from "../../assets/images/ai-image.jpg";

export default function Example() {
  const { t } = useTranslation();

  const features = [
    {
      shortText: t('expertise.webDev.shortText'),
      name: t('expertise.webDev.title'),
      description: t('expertise.webDev.description'),
      img: webDev,
    },
    {
      shortText: t('expertise.automation.shortText'),
      name: t('expertise.automation.title'),
      description: t('expertise.automation.description'),
      img: automation,
    },
    {
      shortText: t('expertise.team.shortText'),
      name: t('expertise.team.title'),
      description: t('expertise.team.description'),
      img: team,
    },
    {
      shortText: t('expertise.ai.shortText'),
      name: t('expertise.ai.title'),
      description: t('expertise.ai.description'),
      img: ai,
    },
    {
      shortText: t('expertise.agents.shortText'),
      name: t('expertise.agents.title'),
      description: t('expertise.agents.description'),
      img: agents,
    },
    {
      shortText: t('expertise.forecasting.shortText'),
      name: t('expertise.forecasting.title'),
      description: t('expertise.forecasting.description'),
      img: "https://img.freepik.com/free-vector/manager-presenting-report-colleagues-partners-investors-diagram-bar-chart-graph-flat-vector-illustration-business-presentation-analysis_74855-8460.jpg?w=996&t=st=1687365039~exp=1687365639~hmac=3c12776822f21eaf64633702e109bc4b817629044bceda52cc2c4bd6f89a13a7",
    },
  ]

  return (
    <>
      <div id="services"></div>
      <StatContainer
        value={t('expertise.title')}
      />
      <div className="overflow-hidden bg-white py-1 sm:py-2">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid place-items-center">
            {features.map((feature, index) => (
              <div
                key={index}
                className={`mx-auto grid place-items-center py-10 grid-cols-1 gap-x-10 gap-y-16 sm:gap-y-20 lg:grid-cols-2 ${
                  index % 2 === 1 ? 'lg:flex-row-reverse' : ''}`}
              >
                <div className={`lg:pt-4 ${index % 2 === 1 ? 'lg:order-2' : ''}`}>
                  <div className="px-0">
                    <h2 className="text-xs font-semibold leading-7 text-gray-600">{feature.shortText}</h2>
                    <p className="text-1xl font-bold tracking-tight text-gray-900 sm:text-2xl"> {feature.name} </p>
                    <p className="mt-6 text-md leading-7 text-gray-600">
                      {feature.description}
                    </p>
                  </div>
                </div>
                <div className={`flex items-center justify-center w-full ${index % 2 === 1 ? 'lg:order-1' : ''}`}>
                  <div className="relative w-full max-w-[35rem] aspect-[16/9] rounded-xl shadow-xl ring-1 ring-gray-400/10 overflow-hidden">
                    <img
                      src={feature.img}
                      alt={`${feature.name} illustration`}
                      className="absolute inset-0 w-full h-full object-cover"
                      width={2432}
                      height={1442}
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}