import { useTranslation } from 'react-i18next';
import { CheckBadgeIcon } from '@heroicons/react/20/solid';
import StatContainer from "../../components/StatContainer";

export default function Example() {
  const { t } = useTranslation();

  const features = [
    {
      name: t('weOffer.tailored.title'),
      description: t('weOffer.tailored.description'),
      icon: CheckBadgeIcon,
    },
    {
      name: t('weOffer.streamlined.title'),
      description: t('weOffer.streamlined.description'),
      icon: CheckBadgeIcon,
    },
  ];

  return (
    <>
      <StatContainer
        value={t('weOffer.title')}
      />
   
    <div  id='offer'></div>
    <div className="overflow-hidden bg-white py-4 sm:py-10">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid place-items-center grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2">
          <div className="lg:pr-8 ">
            <div className="">
              {/* <h2 className="text-base font-semibold leading-7 text-indigo-600">Deploy faster</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">A better workflow</p> */}

              <dl className="space-y-10 leading-7 text-gray-600">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
            src="https://img.freepik.com/free-photo/blank-white-notepad-surrounded-by-empty-cup-crumpled-paper-pen-earphone-black-backdrop_23-2148080455.jpg?w=996&t=st=1687898484~exp=1687899084~hmac=3cec77df2c931ed6105329d165fdcb262d9b10d8cb0c93d094ff0f15cfc0da67"
            alt="Product screenshot"
            className="w-[30rem] rounded-xl shadow-md sm:shadow-xl ring-1 ring-gray-400/10 sm:w-[40rem]"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>

    </>
  );
}
