import { useTranslation } from 'react-i18next';

const ContactUs = () => {
    const { t } = useTranslation();

    const includedFeatures = [
        t('contact.address'),
        '',
        t('contact.phone'),
        '',
        t('contact.email'),
    ]
      
    return ( 
        <>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-gray-800">
                {t('contact.title')}
              </h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
              {includedFeatures.map((feature, index) => (
                <li key={index} className="flex gap-x-3">
                  {feature}
                </li>
              ))}
            </ul>
        </>
     );
}
 
export default ContactUs;