import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Home from './pages/home/Home';
import { detectUserLanguage, getLanguageFromURL, isValidLanguage } from './utils/languageDetector';
import { changeLanguage } from './i18n/i18n';

const metaTags = {
  en: {
    title: "TechVenia - Custom Software Development & IT Solutions",
    metaTitle: "TechVenia - Custom Software Development & IT Solutions",
    description: "TechVenia delivers cutting-edge software development, cloud solutions, and digital transformation services. Expert IT consulting for businesses worldwide.",
    keywords: "software development, IT solutions, cloud computing, digital transformation, custom software, web development, mobile apps, IT consulting"
  },
  fr: {
    title: "TechVenia - Développement de logiciels sur mesure et solutions informatiques",
    metaTitle: "TechVenia - Développement de logiciels sur mesure et solutions informatiques",
    description: "TechVenia offre des services innovants en développement de logiciels, solutions cloud, et transformation numérique. Conseil en informatique pour entreprises à l'échelle mondiale.",
    keywords: "développement de logiciels, solutions informatiques, informatique en nuage, transformation numérique, logiciels sur mesure, développement web, applications mobiles, conseil en informatique"
  },
  nl: {
    title: "TechVenia - Maatwerk Software Ontwikkeling & IT-oplossingen",
    metaTitle: "TechVenia - Maatwerk Software Ontwikkeling & IT-oplossingen",
    description: "TechVenia biedt hoogwaardige softwareontwikkeling, cloud-oplossingen en digitale transformatiediensten. IT-advies voor bedrijven wereldwijd.",
    keywords: "softwareontwikkeling, IT-oplossingen, cloud computing, digitale transformatie, maatwerk software, webontwikkeling, mobiele apps, IT-consultancy"
  }
};

// Language wrapper component
const LanguageWrapper = ({ children }) => {
  const { lang } = useParams();
  const location = useLocation();
  const currentPath = location.pathname;
  const baseUrl = 'https://www.techvenia.com';
  const currentLang = lang || 'en';
  const currentMeta = metaTags[currentLang];

  // Determine the canonical URL
  const getCanonicalUrl = () => {
    // If we're at the root or default language path, use the main domain
    if (currentPath === '/' || currentPath === '/en') {
      return baseUrl;
    }
    // For other language paths, include the full path
    return `${baseUrl}${currentPath}`;
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "url": baseUrl,
    "name": "TechVenia",
    "@graph": [
      {
        "@type": "WebSite",
        "inLanguage": ["en", "fr", "nl"],
        "availableLanguage": [
          {
            "@type": "Language",
            "name": "English",
            "alternateName": "en",
            "url": `${baseUrl}/en`
          },
          {
            "@type": "Language",
            "name": "French",
            "alternateName": "fr",
            "url": `${baseUrl}/fr`
          },
          {
            "@type": "Language",
            "name": "Dutch",
            "alternateName": "nl",
            "url": `${baseUrl}/nl`
          }
        ]
      }
    ]
  };

  useEffect(() => {
    if (lang && isValidLanguage(lang)) {
      changeLanguage(lang);
    }
  }, [lang]);

  return (
    <>
      <Helmet>
        <title>{currentMeta.title}</title>
        <meta name="title" content={currentMeta.metaTitle} lang={currentLang} />
        <meta name="description" content={currentMeta.description} lang={currentLang} />
        <meta name="keywords" content={currentMeta.keywords} lang={currentLang} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href={getCanonicalUrl()} />
        <link rel="alternate" hrefLang="en" href={`${baseUrl}/en`} />
        <link rel="alternate" hrefLang="fr" href={`${baseUrl}/fr`} />
        <link rel="alternate" hrefLang="nl" href={`${baseUrl}/nl`} />
        <link rel="alternate" hrefLang="x-default" href={baseUrl} />
        <meta httpEquiv="content-language" content={currentLang} />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      {children}
    </>
  );
};

function App() {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash && hash.startsWith('#/')) {
      const path = hash.slice(1);
      window.history.replaceState(null, '', path);
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route 
          path="/" 
          element={<Navigate to={`/${detectUserLanguage()}`} replace />} 
        />
        <Route 
          path="/:lang/*" 
          element={
            <LanguageWrapper>
              <Home />
            </LanguageWrapper>
          } 
        />
        <Route 
          path="*" 
          element={<Navigate to={`/${detectUserLanguage()}`} replace />} 
        />
      </Routes>
    </Router>
  );
}

export default App;
