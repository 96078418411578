import React from "react";
import { useTranslation } from 'react-i18next';

function Card({ imageUrl, title, description, buttonUrl, text, backgroundColor, imageUrlP }) {
  const { t } = useTranslation();
  
  return (
    <div className={`cardProject flex flex-row w-[360px] sm:w-[700px] h-[400px] rounded-lg shadow ${backgroundColor} border border-gray-100 transition-all duration-300`}>
      <div className="flex flex-col justify-between py-5 flex-1 min-w-0">
        <a 
          href={buttonUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="block px-3"
          aria-label={`Visit ${title} website`}
        >
          <img 
            className="rounded-t-lg w-auto h-[40px] max-w-[200px] object-contain" 
            src={imageUrl} 
            alt={`${title} logo`}
          />
        </a>
        
        <div className="px-3 flex-1 overflow-hidden">
          <h3 className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white py-4">
            {description}
          </h3>
        </div>

        <div className="flex items-center px-3 mt-auto">
          <a
            href={buttonUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="text-white font-medium rounded-lg text-md py-2.5 hover:underline"
            aria-label={`Learn more about ${title}`}
          >
            {title}
          </a>
          <div className="bg-white rounded-full w-10 h-10 ml-2 flex-shrink-0 flex items-center justify-center hover:bg-gray-100 transition-colors">
            <svg 
              className="w-6 h-6 text-gray-800" 
              aria-hidden="true" 
              xmlns="http://www.w3.org/2000/svg" 
              fill="none" 
              viewBox="0 0 8 14"
            >
              <path 
                stroke="currentColor" 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth="2" 
                d="m1 13 5.7-5.326a.909.909 0 0 0 0-1.348L1 1"
              />
            </svg>
          </div>
        </div>
      </div>

      <div className="p-2 flex items-center justify-center w-[300px] hidden sm:flex flex-shrink-0">
        <img 
          src={imageUrlP} 
          alt={`${title} project preview`}
          className="max-w-full max-h-[300px] object-contain"
          loading="lazy"
        />
      </div>
    </div>    
  );
}

export default Card;