import { useTranslation } from 'react-i18next';
import { lazy } from 'react';

// Lazy load the product screenshot
const ProductImage = lazy(() => import('../../components/atoms/ProductImage'));

export default function Intro() {
  const { t } = useTranslation();

  return (
    <>
      <div className="overflow-hidden relative" id="home">
        <div className="bgWhite1"></div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8 py-20 sm:py-40">
          <div className="mx-auto grid place-items-center grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2">
            <div className="">
              <div className="">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                  {t('intro.title')}
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  {t('intro.subtitle')}
                </p>
                <div className="mt-10 flex items-start gap-x-6 sm:pb-5 pb-4">
                  <a
                    href="#contact"
                    className="rounded-md bg-gray-800 px-6 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                  >
                    {t('intro.getStarted')}
                  </a>
                </div>
              </div>
            </div>
            <ProductImage />
          </div>
        </div>
      </div>
    </>
  )
}