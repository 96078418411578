import { CheckBadgeIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import img from "../../assets/images/Scientist_Profile.jpg";

export default function Example() {
  const { t } = useTranslation();

  const features = [
    {
      name: t('scivenia_section.features.events.title'),
      description: t('scivenia_section.features.events.description'),
      icon: CheckBadgeIcon,
    },
    {
      name: t('scivenia_section.features.scientists.title'),
      description: t('scivenia_section.features.scientists.description'),
      icon: CheckBadgeIcon,
    },
    {
      name: t('scivenia_section.features.organisers.title'),
      description: t('scivenia_section.features.organisers.description'),
      icon: CheckBadgeIcon,
    },
  ];

  return (
    <> 
      <div className="overflow-hidden bg-white py-2 sm:py-8">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid place-items-center grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2">
            <div className="">
              <h2 className="text-base font-semibold leading-7 text-gray-600">
                {t('scivenia_section.subtitle')}
              </h2>
              <p className="text-base mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                SciVenia
              </p>

              <dl className="sm:mt-8 mt-2 space-y-10 text-base leading-7 text-gray-600 ">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
            <img
              src={img}
              alt="Product screenshot"
              className="w-[30rem] rounded-xl shadow-md sm:shadow-xl ring-1 ring-gray-400/10 sm:w-[40rem]"
              width={2432}
              height={1442}
            />
          </div>
        </div>
      </div>
    </>
  )
}