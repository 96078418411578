import { useTranslation } from 'react-i18next';
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import Card from "./ProjectCards";

function Carousel() {
  const { t } = useTranslation();

  const getScrollWidth = () => {
    if (window.innerWidth < 768) {
      return 390;
    } else if (window.innerWidth < 1024) {
      return 720;
    } else {
      return 720;
    }
  };

  const scrollLeft = () => {
    const contentElement = document.getElementById("content");
    if (contentElement) {
      const scrollWidth = getScrollWidth();
      contentElement.scrollLeft -= scrollWidth;
    }
  };

  const scrollRight = () => {
    const contentElement = document.getElementById("content");
    if (contentElement) {
      const scrollWidth = getScrollWidth();
      contentElement.scrollLeft += scrollWidth;
    }
  };

  const projects = [
    {
      imageUrl: "https://scivenia.com/images/sv_logo_2.png",
      title: t('projects.scivenia.title'),
      description: t('projects.scivenia.description'),
      text: t('projects.scivenia.text'),
      buttonUrl: "https://scivenia.com/en",
      backgroundColor: "bcg1",
      imageUrlP: "https://horizonte.as/wp-content/uploads/2017/06/iPad-Pro-9.7-White-Mockup-min.png"
    },
    {
      imageUrl: "https://www.mubazar.com/_next/image?url=%2Fimages%2Fmubazar-logo-final-white.png&w=256&q=75",
      title: t('projects.mubazar.title'),
      description: t('projects.mubazar.description'),
      text: t('projects.mubazar.text'),
      buttonUrl: "https://www.mubazar.com/en",
      backgroundColor: "bcg2",
      imageUrlP: "https://horizonte.as/wp-content/uploads/2017/06/iPad-Pro-9.7-White-Mockup-min.png"
    },
    {
      imageUrl: "https://www.gemone.com/wp-content/themes/gemone-theme/assets//images/svg/logo-black.svg",
      title: t('projects.gemone.title'),
      description: t('projects.gemone.description'),
      text: t('projects.gemone.text'),
      buttonUrl: "https://www.gemone.com/en/about-us/",
      backgroundColor: "bcg3",
      imageUrlP: "https://horizonte.as/wp-content/uploads/2017/06/iPad-Pro-9.7-White-Mockup-min.png"
    },
    {
      imageUrl: "http://europe-at-school.eu/wp-content/uploads/2019/07/cropped-Logo-Horizontal-01.png",
      title: t('projects.europe.title'),
      description: t('projects.europe.description'),
      text: t('projects.europe.text'),
      buttonUrl: "https://europe-at-school.eu/en/about-us/",
      backgroundColor: "bcg1",
      imageUrlP: "https://horizonte.as/wp-content/uploads/2017/06/iPad-Pro-9.7-White-Mockup-min.png"
    },
    {
      imageUrl: "https://grid.mk/img/brand/logo.png",
      title: t('projects.grid.title'),
      description: t('projects.grid.description'),
      text: t('projects.grid.text'),
      buttonUrl: "https://grid.mk/",
      backgroundColor: "bcg2",
      imageUrlP: "https://horizonte.as/wp-content/uploads/2017/06/iPad-Pro-9.7-White-Mockup-min.png"
    },
    {
      imageUrl: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjFlbSIgaGVpZ2h0PSIxZW0iIHZpZXdCb3g9IjAgMCAzMiAzMiIgcm9sZT0iaW1nIiBmaWxsPSJjdXJyZW50Q29sb3IiIGNvbXBvbmVudG5hbWU9Im9yY2hpZC1pY29uIj4KICAgIDxwYXRoIGQ9Ik0yOS41IDdjLTEuMzgxIDAtMi41IDEuMTItMi41IDIuNSAwIDAuMjg0IDAuMDU4IDAuNTUxIDAuMTQ0IDAuODA1bC02LjA5NCA1LjI0N2MtMC40MjctMC4zNDEtMC45NjEtMC41NTMtMS41NS0wLjU1My0wLjY4IDAtMS4yOTQgMC4yNzMtMS43NDQgMC43MTNsLTQuNzc0LTIuMzljLTAuMDkzLTEuMjk2LTEuMTYyLTIuMzIzLTIuNDgyLTIuMzIzLTEuMzggMC0yLjUgMS4xMi0yLjUgMi41IDAgMC4zNzggMC4wOTAgMC43MzIgMC4yNCAxLjA1M2wtNC44NjcgNS42MTJjLTAuMjczLTAuMTAyLTAuNTY0LTAuMTY2LTAuODczLTAuMTY2LTEuMzgxIDAtMi41IDEuMTE5LTIuNSAyLjVzMS4xMTkgMi41IDIuNSAyLjVjMS4zODEgMCAyLjUtMS4xMTkgMi41LTIuNSAwLTAuMzMyLTAuMDY4LTAuNjQ5LTAuMTg2LTAuOTM5bDQuOTQ2LTUuNjg1YzAuMjM2IDAuMDczIDAuNDggMC4xMjQgMC43NCAwLjEyNCAwLjcyNyAwIDEuMzc3LTAuMzE2IDEuODM0LTAuODEzbDQuNjY5IDIuMzQxYzAuMDE3IDEuMzY3IDEuMTI3IDIuNDcxIDIuNDk3IDIuNDcxIDEuMzgxIDAgMi41LTEuMTE5IDIuNS0yLjUgMC0wLjA0NC0wLjAxMS0wLjA4Ni0wLjAxMy0wLjEzbDYuNTAzLTUuNTg3YzAuMzA5IDAuMTM3IDAuNjQ5IDAuMjE2IDEuMDEwIDAuMjE2IDEuMzgxIDAgMi41LTEuMTE5IDIuNS0yLjVzLTEuMTE5LTIuNS0yLjUtMi41eiIvPgo8L3N2Zz4=",
      title: t('projects.abf.title'),
      description: t('projects.abf.description'),
      text: t('projects.abf.text'),
      buttonUrl: "https://dashboard.abf.vicre.eu/",
      backgroundColor: "bcg3",
      imageUrlP: "https://horizonte.as/wp-content/uploads/2017/06/iPad-Pro-9.7-White-Mockup-min.png"
    }
  ];

  return (
    <section aria-label={t('projects.title')} id="projects">
      <div className="relative py-10 px-4">
        <h2 className="text-center py-4 text-4xl sm:text-5xl font-bold">
          {t('projects.title')}
        </h2>
        
        <div className="absolute right-0 top-11" role="group" aria-label={t('carousel.navigation')}>
          <button 
            onClick={scrollLeft} 
            className="p-2 m-2 rounded-full bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500" 
            aria-label={t('carousel.previous')}
          >
            <FiChevronLeft aria-hidden="true" />
          </button>
          <button 
            onClick={scrollRight} 
            className="p-2 m-2 rounded-full bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500" 
            aria-label={t('carousel.next')}
          >
            <FiChevronRight aria-hidden="true" />
          </button>
        </div>

        <div 
          id="content" 
          className="carousel flex items-center justify-start gap-6 overflow-x-auto scroll-smooth hide-scrollbar no-scrollbar pb-4"
          role="region"
          aria-label={t('carousel.projectsList')}
          style={{
            scrollBehavior: 'smooth',
            WebkitOverflowScrolling: 'touch',
            scrollbarWidth: 'none',  // Firefox
            msOverflowStyle: 'none'  // IE/Edge
          }}
        >
          {projects.map((project, index) => (
            <div 
              key={project.title}
              className="flex-none"
              role="group" 
              aria-label={`${t('projects.project')} ${index + 1}: ${project.title}`}
            >
              <Card {...project} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Carousel;