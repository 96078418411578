const defaultLanguage = 'en';
const supportedLanguages = ['en', 'fr', 'nl'];

// Language mapping for regions
const regionLanguages = {
  'BE': 'nl', // Belgium -> Dutch
  'FR': 'fr', // France -> French
  'NL': 'nl', // Netherlands -> Dutch
  'GB': 'en', // UK -> English
  'US': 'en'  // USA -> English
};

export const detectUserLanguage = () => {
  try {
    // Try to get region from navigator
    const regionFromNavigator = navigator.language.split('-')[1] || navigator.language;
    const browserLang = navigator.language.split('-')[0];
    
    // Check if we have a specific language for this region
    if (regionLanguages[regionFromNavigator]) {
      return regionLanguages[regionFromNavigator];
    }
    
    // Check if browser language is supported
    if (supportedLanguages.includes(browserLang)) {
      return browserLang;
    }

    // Fallback to default language
    return defaultLanguage;
  } catch (error) {
    console.warn('Error detecting language:', error);
    return defaultLanguage;
  }
};

export const getLanguageFromURL = () => {
  try {
    const path = window.location.pathname;
    const language = path.split('/')[1];
    
    if (supportedLanguages.includes(language)) {
      return language;
    }
    
    return null;
  } catch (error) {
    console.warn('Error getting language from URL:', error);
    return null;
  }
};

export const isValidLanguage = (lang) => {
  return supportedLanguages.includes(lang);
}; 