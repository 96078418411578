import { useTranslation } from 'react-i18next';
import Daniel from "../../assets/images/Daniel.jpg";
import Ben from "../../assets/images/Ben.jpg";
import Marija from "../../assets/images/Marija.jpg";
import Mudassar from "../../assets/images/Mudassar.jpg";
import Mariche from "../../assets/images/Mariche.jpg";
import Uchenna from "../../assets/images/Uchenna.jpg";
import Viktor from "../../assets/images/viktor.jpg";
import Dane from "../../assets/images/dane.jpeg";

export default function Example() {
  const { t } = useTranslation();

  const people = [
    {
      name: 'Dr. Darko Bozhinoski',
      role: t('team.leadership.roles.ceo'),
      imageUrl: 'https://www.techvenia.com/img/darko2.jpg',
      socialUrl: 'https://twitter.com/darko_bozinoski',
      liUrl: 'https://www.linkedin.com/in/darko-bozhinoski-ph-d-75bbb629/',
    },
    {
      name: 'Dr. Jamal Wakim',
      role: t('team.leadership.roles.cfo'),
      imageUrl: 'https://www.techvenia.com/img/Jamal.jpg',
      socialUrl: 'https://twitter.com/',
      liUrl: 'https://www.linkedin.com/in/jamal-wakim-phd-pharmd/',
    },
    {
      name: 'Mudassar Ali',
      role: t('team.members.roles.fullstack'),
      imageUrl: `${Mudassar}`,
      socialUrl: 'https://twitter.com/',
      liUrl: 'https://www.linkedin.com/in/mudsr-ali/',
    },
  ];

  const team = [
    {
      name: 'Dane Josifovski',
      role: t('team.members.roles.business'),
      imageUrl: `${Dane}`,
      socialUrl: 'https://twitter.com/',
      liUrl: 'https://www.linkedin.com/in/dane-josifovski-b838ba52/',
    },
    {
      name: 'Viktor Ristevski',
      role: t('team.members.roles.designer'),
      imageUrl: `${Viktor}`,
      socialUrl: 'https://twitter.com/',
      liUrl: 'https://www.linkedin.com/in/viktorristevski/?originalSubdomain=mk',
    },
    {
      name: 'Ben Awino',
      role: t('team.members.roles.softwareEngineer'),
      imageUrl: `${Ben}`,
      socialUrl: 'https://twitter.com/',
      liUrl: 'https://www.linkedin.com/in/ben-awino-214b72100/',
    },
    {
      name: 'Daniel Elambo Atonge',
      role: t('team.members.roles.softwareEngineer'),
      imageUrl: `${Daniel}`,
      socialUrl: 'https://twitter.com/',
      liUrl: 'https://www.linkedin.com/in/danielatonge/',
    },
    {
      name: 'Uchenna Ugwumadu',
      role: t('team.members.roles.softwareEngineer'),
      imageUrl: `${Uchenna}`,
      socialUrl: 'https://twitter.com/',
      liUrl: 'https://www.linkedin.com/in/josebright/',
    },
    {
      name: 'Marija Milevska',
      role: t('team.members.roles.frontend'),
      imageUrl: `${Marija}`,
      socialUrl: 'https://twitter.com/',
      liUrl: 'https://www.linkedin.com/in/marija-milevska-87a87b16a/',
    },
    {
      name: 'Mariche Trpcheska',
      role: t('team.members.roles.qa'),
      imageUrl: `${Mariche}`,
      socialUrl: 'https://twitter.com/',
      liUrl: '',
    },
   
  ];

  const SocialLinks = ({ person }) => (
    <div className="flex items-center">
      <a 
        href={person.socialUrl} 
        target="_blank" 
        rel="noopener noreferrer"
        aria-label={`${person.name}'s Twitter profile`}
        className="hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 fill-gray-600" fill="fill-sky-50" viewBox="0 0 24 24" aria-hidden="true">
          <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
        </svg>
      </a>
      <a 
        href={person.liUrl} 
        target="_blank" 
        rel="noopener noreferrer"
        aria-label={`${person.name}'s LinkedIn profile`}
        className="mx-2 hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 fill-gray-600" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
          <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
        </svg>
      </a>
    </div>
  );

  return (
    <>
      <div id="team"></div>
      <div className="bg-white py-5 sm:py-10 mt-5 sm:mt-20">
        <div className="mx-auto grid max-w-7xl px-6 lg:px-8 gap-x-8 gap-y-5 xl:grid-cols-3">
          <div className="">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {t('team.leadership.title')}
            </h2>
          </div>
          <ul className="grid gap-x-2 gap-y-12 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 sm:gap-y-16 xl:col-span-2">
            {people.map((person) => (
              <li key={person.name}>
                <div className="flex items-center gap-x-6">
                  <img 
                    className="h-16 w-16 rounded-full" 
                    src={person.imageUrl} 
                    alt={`${person.name}'s profile`} 
                  />
                  <div>
                    <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                      {person.name}
                    </h3>
                    <p className="text-xs font-semibold leading-6 text-gray-600">
                      {person.role}
                    </p>
                    <SocialLinks person={person} />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="mx-auto grid max-w-7xl px-6 lg:px-8 gap-x-8 gap-y-5 xl:grid-cols-3 py-20">
          <div className="max-w-2xl">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {t('team.members.title')}
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              {t('team.members.subtitle')}
            </p>
          </div>
          <ul className="grid gap-x-8 gap-y-12 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 sm:gap-y-16 xl:col-span-2">
            {team.map((person) => (
              <li key={person.name}>
                <div className="flex items-center gap-x-6">
                  <img 
                    className="h-16 w-16 rounded-full" 
                    src={person.imageUrl} 
                    alt={`${person.name}'s profile`} 
                  />
                  <div>
                    <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                      {person.name}
                    </h3>
                    <p className="text-xs font-semibold leading-6 text-gray-600">
                      {person.role}
                    </p>
                    <SocialLinks person={person} />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
  